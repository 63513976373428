

console.log('%ccommon js', 'color: yellow');

import Cookies from 'js-cookie';
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';
import { VueReCaptcha } from 'vue-recaptcha-v3'

import * as Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import {vfmPlugin, ModalsContainer} from 'vue-final-modal';
import DFlatMortgageFormPopup from '@components/organisms/DFlatMortgageFormPopup.vue';
import DCallMeFormPopup from '@components/organisms/DCallMeFormPopup.vue';
import DCallMeFormPopupNational from '@components/organisms/DCallMeFormPopupNational.vue';
import DPopupDisclaimer from '@components/organisms/DPopupDisclaimer.vue';
import DPopupAgreement from '@components/organisms/DPopupAgreement.vue';
import DPopupSuccess from '@components/organisms/DPopupSuccess.vue';
import DCallMeForm from '@components/organisms/DCallMeForm.vue';
import DFlatViewPopup from '@components/organisms/DFlatViewPopup.vue';
import DPressCenterFormPopup from '@components/molecules/DPressCenterFormPopup.vue';
import DPopupBlackFriday from '@components/organisms/DPopupBlackFriday.vue'; // Info: Black Friday popup 2023-07-17
import DCountdown from '@components/atoms/DCountdown.vue';
import DNotify from '@components/atoms/DNotify.vue';

import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css'; // optional for styling
import '../styles/libs/magnific-popup.css';
import './libs/jquery.magnific-popup.min';
import initSocialShare from '@modules/legacy/socialShare';
import '@modules/legacy/dsWidgetGoodies';
import '@utils/favorites';
import '@utils/search';
import '@utils/menu';
import '@utils/tabs';
import '@utils/utm';
import '@utils/tabsSlider'
import '@utils/snow'

$(window).on('load', async function(e) {
    initSocialShare()
    const dCountdownEl = document.querySelector('.d-countdown');
    if (dCountdownEl) {
        if (dCountdownEl.dataset.endDate) Vue.createApp(DCountdown, {...dCountdownEl.dataset}).mount(dCountdownEl);
        else Vue.createApp(DNotify, {...dCountdownEl.dataset}).mount(dCountdownEl);
    }

    const root = Vue.createApp({
        components: {ModalsContainer},
    });

    root.component('DFlatMortgageFormPopup', DFlatMortgageFormPopup);
    root.component('DCallMeFormPopup', DCallMeFormPopup);
    root.component('DCallMeFormPopupNational', DCallMeFormPopupNational);
    root.component('DPopupDisclaimer', DPopupDisclaimer);
    root.component('DPopupAgreement', DPopupAgreement);
    root.component('DPopupSuccess', DPopupSuccess);
    root.component('DFlatViewPopup', DFlatViewPopup);
    root.component('DPressCenterFormPopup', DPressCenterFormPopup);
    if((window.blackFriday && window.blackFriday.active) || (window.popup30year && window.popup30year.active)) {
        root.component('DPopupBlackFriday', DPopupBlackFriday);
    }
    root.use(vfmPlugin);
    root.use(VueAxios, axios);
    root.use(VueReCaptcha, window.recaptchaOptions);
    window.layoutAapp = root.mount('#layout-app');

    if (document.querySelector('.d-still-questions__inner:not([data-mod="national"])')) {
        Vue.createApp({components: {DCallMeForm}}).use(VueAxios, axios).use(VueReCaptcha, window.recaptchaOptions).mount('.d-still-questions__inner');
    }

    //Info: Black Friday popup 2023-07-17
    const popupShown = Cookies.get('blackFriday');
    if (!popupShown && window.blackFriday && window.blackFriday.active) {
        window.layoutAapp.$vfm.show({component: 'DPopupBlackFriday'});
        Cookies.set('blackFriday', 1, {expires: 30, path: '/'});
    }

    const button30year = document.querySelector('button.d-sticker30years');
    if (button30year && window.popup30year && window.popup30year.active) {
        button30year.addEventListener('click', () => {
            window.layoutAapp.$vfm.show({component: 'DPopupBlackFriday'});
        })

    }
    (async () => {
        const searchParamsHasCursor = Array.from((new URLSearchParams(window.location.search)).keys())
      if (window.customCursor || searchParamsHasCursor.includes('cursor')) {
          await import("@utils/cursor.js");
      }
    })();

    window.addEventListener("popstate", openCallMePopup)
    openCallMePopup()


});

const openCallMePopup = (data = {}, openCallMe = false) => {
    openCallMe = openCallMe || window.location.hash.match(/callme/)
    if (openCallMe) window.layoutAapp.$vfm.show({component: 'DCallMeFormPopup', bind: Object.assign({}, document.querySelector('[data-callMe-show][data-action-url][data-politics-link]').dataset, data)});
}

$(document).on('click', '[data-callMe-show]', function(e) {
    e.preventDefault();
    openCallMePopup(this.dataset, true)
});
$(document).on('click', '[data-callMe-show-national]', function(e) {
    e.preventDefault();
    window.layoutAapp.$vfm.show({component: 'DCallMeFormPopupNational', bind: Object.assign({}, document.querySelector('[data-callMe-show-national][data-action-url]').dataset, this.dataset)});
});
$(document).on('click', '[data-pressform-show]', function(e) {
    e.preventDefault();
    window.layoutAapp.$vfm.show({component: 'DPressCenterFormPopup', bind: Object.assign({}, document.querySelector('[data-callMe-show][data-action-url][data-politics-link]').dataset, this.dataset)});
    window.menuOnOff(false);
    return false;
});
$(document).on('click', '[data-disclaimer-show]', function(e) {
    e.preventDefault();
    window.layoutAapp.$vfm.show({component: 'DPopupDisclaimer', bind: Object.assign({}, window.dStrings)});
});
$(document).on('click', '[data-agreement-show]', function(e) {
    e.preventDefault();
    window.layoutAapp.$vfm.show({component: 'DPopupAgreement', bind: Object.assign({}, window.dStrings)});
});

$(document).on('click', '[data-share-link]', function(e) {
    e.preventDefault();
    $(this).toggleClass('_active');
});

let lastScrollTop = 0;

$(window).on('scroll', function() {
    let st = window.pageYOffset || document.documentElement.scrollTop;
    // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
    const $tablet = window.matchMedia("(max-width: 1279px)").matches
    const $header =  $('[data-header]')
    const $body =  $('body')
    //if (st > lastScrollTop) $header .addClass('_hide');
    //else $header .removeClass('_hide');
    if (st > lastScrollTop){
        // downscroll code

        $body.addClass('_noHeader')
        if ($tablet) {
            $header.removeClass('_fixed')
            $header.addClass('_hide')
            $('[data-left-column-fix]').removeClass('_noHeader');
        } else {
            $header.addClass('_hide')
            $('[data-left-column-fix]').addClass('_noHeader')
        }

    } else {
        // upscroll code
        $body.removeClass('_noHeader')
        if ($tablet) {
            $header.addClass('_fixed')
            $header.removeClass('_hide')
            $('[data-left-column-fix]').removeClass('_noHeader');
        } else {
            $header.removeClass('_hide')
            $('[data-left-column-fix]').removeClass('_noHeader');
        }


    }
    lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
});


$(document).on('click', function(e) {
    if (e.target.nodeName === 'circle' && e.target.className.baseVal === 'circle-tooltip-svg') {
        $(e.target.parentNode.parentNode).toggleClass('__tooltip-show');
        return false;
    }
    else {
        const disallowElements = ['svg', 'path', 'polygon', 'circle', 'use'];
        if (disallowElements.includes(e.target.nodeName)
            || !e.target.className.includes('ba-header-top__searchButton')
            && !e.target.className.includes('search__text')
            && !e.target.className.includes('search__button')) {
            $('[data-search-wrap]').removeClass('_show');
            $('[data-search]').removeClass('_show');
        }
    }
});


/*------*/
// фикс блока высотой в 100vh а мобилке, где навбар браузера добавляет скролл
function setVH() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${ vh }px`);
}

$(window).on('resize', setVH);
$(window).on('load', setVH);

window.pjax_loadPage = (urlVal) => {
    window.location = urlVal;
};


$(document).on('click', 'a[data-utp-link], [data-popup-content], a.d-index-utp-slide__link', popupContentInit);

function popupContentInit(e) {
    e.preventDefault();
    const contentUrl = $(this).attr('href');
    if (contentUrl && (contentUrl.match(/ajax\/utp/) || this.hasAttribute('data-popup-content'))) {

        $.ajax({
            url: contentUrl,
            context: document.body,
        }).done(function(response) {
            const content = response.content;
            $.magnificPopup.open({
                mainClass: 'd-popup-content-wrap',
                fixedContentPos: true,
                items: {
                    src: `
                    <div class="d-popup-content">
                        <div class="d-popup-content__text">${ content }</div>
                        <button type="button" class="d-popup-content__close _small d-button _close _blue">
                          <img src="/assets/blueant/assets/img/d/icon_close.svg" alt="">
                        </button>
                    </div>
                `,
                },
                callbacks: {
                    open: function() {
                        const $closeBtn = this.content.find('.d-popup-content__close');

                        $closeBtn.on('click', function() {
                            $.magnificPopup.close();
                        });
                        // $('body').addClass('_popupOpen')
                    },
                    close: function() {
                        const $closeBtn = this.content.find('.d-popup-content__close');
                        $closeBtn.off('click');
                        // $('body').removeClass('_popupOpen')
                    },
                },
                type: 'inline',
            }, 0);
        });
    }

}


/*------*/

const $utpSlider = $('[data-utp-text]');
const $utpSlides = $utpSlider.length ? $utpSlider.find('.swiper-slide') : [];
const utpSlidesCount = $utpSlides.length;

if ($utpSlider.length) {
    const sliderUtpText = new Swiper('[data-utp-text]', {
        loop: utpSlidesCount > 2,
        slidesPerView: 'auto',
        spaceBetween: 32,
        navigation: {
            nextEl: '.osUTPText._next',
            prevEl: '.osUTPText._prev',
        },
        breakpoints: {
            320: {
                slidesPerView: utpSlidesCount > 1 ? 'auto' : 1,
                spaceBetween: 12,
                loop: utpSlidesCount > 1,
            },
            768: {
                slidesPerView: 'auto',
                spaceBetween: 32,
                loop: true,
            },
            1024: {
                loop: utpSlidesCount > 2,
                slidesPerView: 2,
            },
            1280: {
                slidesPerView: utpSlidesCount > 2 ? 'auto' : 2,
            },
        },
    });

}

const sliderProgress = new Swiper('[data-progress-slider]', {
    loop: true,
    slidesPerView: 3,
    spaceBetween: 32,
    navigation: {
        nextEl: '.progressSlider._next',
        prevEl: '.progressSlider._prev',
    },
    breakpoints: {
        320: {
            slidesPerView: 'auto',
            spaceBetween: 12,
        },
        768: {
            slidesPerView: 'auto',
            spaceBetween: 32,
        },
        1280: {
            slidesPerView: 2,
        },
        1600: {
            slidesPerView: 3,
        },
    },
});
$('[data-map-button]').on('click', function(e) {
    e.preventDefault();
    $('[data-map-info]').toggleClass('_hide');
    $('[data-map]').toggleClass('_hide');
});
$('[data-legend-toggle]').on('click', function(e) {
    e.preventDefault();
    $('[data-legend]').toggleClass('_show');
});
$('[data-text-popup-button]').on('click', function(e) {
    e.preventDefault();
    $('[data-text-popup]').toggleClass('_show');
});
$('[data-location-button]').on('click', function(e) {
    e.preventDefault();
    $('[data-location]').toggleClass('_show');
});
$('[data-gp-button]').on('click', function(e) {
    e.preventDefault();
    $('[data-gp]').toggleClass('_show');
});

if (Cookies.get('agree-cookie') === undefined) {
    $('[data-cookie]').addClass('_show')
}
$('[data-cookie-button]').on('click', function() {
    $('[data-cookie]').removeClass('_show')
    Cookies.set('agree-cookie', '1', { expires: 365 })
})
