import $ from 'jquery'
global.$ = require("jquery");
global.jQuery = global.$;

import SimpleBar from 'simplebar'
import 'simplebar/dist/simplebar.css';

import 'normalize.css';
import './styles/main.sass'
import './js/common'

//import './js/pages/about'
//import './js/pages/parking-search'
//import './js/pages/object-single'
//import './js/pages/contacts'
//import './js/pages/home'


